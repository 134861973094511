import { Big } from 'big.js'
import { numberFormat } from '@base/utils'
export interface UseUSDFormat {
  /** Custom prefix for formatted numbers */
  prefix?: string
  /** Custom suffix for formatted numbers */
  suffix?: string
  /** Display approximately equal sign, default is false */
  showApprox?: boolean
  abbreviate?: boolean
}
/**
 * @description The function for formatting when displaying dollars, it formats numbers into comma-separated format, only shows two decimal places, and limits the minimum displayed number,
 * @export
 * @param {(number | string | Big)} num
 * @param {UseUSDFormat} [options={}]
 * @return {*}
 */
export function useUSDFormat(
  num: number | string | Big,
  options: UseUSDFormat = {},
) {
  const { suffix = '', showApprox = false, abbreviate = false } = options
  // Minimum display number
  const isNegative = Big(num || 0).lt('0')
  const absNum = Big(num ?? 0).abs()
  const isMinNumber = Big(absNum || 0).lt('0.01') && !Big(absNum || 0).eq(0)
  const prefix =
    options.prefix ?? (isMinNumber ? '<$' : showApprox ? '≈ $' : '$')
  const formatNum = numberFormat(absNum, 'en-US', {
    dp: 2,
    minimumFractionDigits: 2,
  })
  let usdString = isMinNumber ? '0.01' : formatNum === '0.00' ? '0' : formatNum

  if (abbreviate) {
    const usdBigNum = Big(usdString.replaceAll(',', ''))
    if (usdBigNum.lt(1000)) {
      return `${isNegative ? '-' : ''}${prefix}${usdString}${suffix}`
    }

    const sizes = [
      { value: 1e12, suffix: 'T' },
      { value: 1e9, suffix: 'B' },
      { value: 1e6, suffix: 'M' },
      { value: 1e3, suffix: 'K' },
    ]

    // Iterate from largest to smallest size
    for (let i = 0; i < sizes.length; i++) {
      if (usdBigNum.gte(sizes[i].value)) {
        const result = usdBigNum.div(sizes[i].value)
        let resultString = ''
        if (!result.mod(1).eq(0)) {
          resultString = result.toFixed(1)
        } else {
          resultString = result.toFixed(0)
        }

        usdString = resultString + sizes[i].suffix
        break
      }
    }
  }

  return `${isNegative ? '-' : ''}${prefix}${usdString}${suffix}`
}
